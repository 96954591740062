//
// Forms
//


// Labels

.form-control-label {
    color: $gray-700;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
}


// Text inputs

.form-control {
    font-size: $input-btn-font-size;

    &:focus {
        &::placeholder {
            color: $input-focus-placeholder-color;
        }
    }
}

.form-checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background-color: #ccc;

    &:hover {
        background-color: #ddd;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        width: 20px;
        height: 20px;
        border-radius: 2px;
    }


    &:checked {
        background-color: $primary;
        &:after {
            left: 7px;
            top: 4px;
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }


}


// Textarea

textarea[resize="none"] {
    resize: none !important;
}

textarea[resize="both"] {
    resize: both !important;
}

textarea[resize="vertical"] {
    resize: vertical !important;
}

textarea[resize="horizontal"] {
    resize: horizontal !important;
}


// Form input variations

// Muted input

.form-control-muted {
    background-color: $input-muted-bg;
    border-color: $input-muted-bg;
    box-shadow: none;

    &:focus {
        background-color: $input-focus-muted-bg;
    }
}


// Alternative input

.form-control-alternative {
    box-shadow: $input-alternative-box-shadow;
    border: 0;
    transition: box-shadow .15s ease;

    &:focus {
        box-shadow: $input-focus-alternative-box-shadow;
    }
}

// Size variations: Fixes to the bootstrap defaults

.form-control-lg {
    font-size: $font-size-base;
}