//
// Navbar
//

@import "../core/navbars/navbar";
@import "../core/navbars/navbar-vertical";
@import "../core/navbars/navbar-search";
@import "../core/navbars/navbar-dropdown";
@import "../core/navbars/navbar-collapse";

@include media-breakpoint-up(md) {
  .navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img {
    max-height: 70px;
  }
  .navbar-vertical .navbar-collapse:before {
    content: '';
    display: none;
  }
}