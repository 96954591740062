//
// Pagination
//

@import "../core/paginations/pagination";

.pagination {
  justify-content: center;
}

.page-item {
  .page-link,
  span {
    border-radius: 0;
    margin: 0;
    width: auto;
    min-width: 43px;
    padding: 0 5px;
  }
}
