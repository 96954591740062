//
// Nav
//


@import "../core/navs/nav";
@import "../core/navs/nav-pills";

ul {
    &.navbar-nav {
        li {
            a {
                &.active {
                    background-color: #ef9a9a;
                }
            }
        }
    }

}